import React from "react";
import Layout from "../layouts/Layout";
import styled from "styled-components";
import TargetFullBanner from "../components/TargetFullBanner";
import DipendentePensionatoPrivato from "../sections/DipendentePensionatoPrivato";
import SliderOne from "../sections/SliderOne";
import MetodoPiuVeloce from "../sections/MetodoPiuVeloce";
import CessioneFinanziamenti from "../sections/CessioneFinanziamenti";
import { getImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";
import DefaultButton from "../components/buttons/DefaultButton";

const StyledWpPage = styled.div`
  .wp-page {
    &__title {
      text-align: center;
    }

    &__vision {
      color: #527191;
      text-align: center;
    }

    &__mission {
      h3 {
        color: #527191;
      }
    }

    &__valori {
      &__item {
        background-color: #e7c700;
        padding: 2em 4em;
        border-radius: 12px;
        width: 325px;
      }
    }

    &__blue-banner {
      background-color: #e8f0f6;
    }

    &__slug a {
      text-decoration: none;
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }

    &__header {
      background: linear-gradient(
        180deg,
        rgba(246, 234, 199, 0.63) 0%,
        rgba(255, 255, 255, 0) 60%
      );
      mix-blend-mode: darken;

      .intro {
        color: #527191;
      }

      .color-gray {
        color: #6d767b;
      }

      .color-yellow {
        color: #e7c700;
      }

      .content {
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .section {
    display: flex;
    align-items: center;
    padding-bottom: 3em;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    &__title {
      color: #e7c700;
      font-size: 30px;
      min-width: 400px;
      align-self: flex-start;
      text-align: left;
    }
  }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-size: auto;
`;

export default function ConosciamociMeglio(props) {
  const {
    visionTopRightImage,
    visionBottomCenterImage,
    visionTopCenterImage,
    visionBottomRightImage,
  } = useStaticQuery(
    graphql`
      query {
        visionTopRightImage: file(
          relativePath: { eq: "vision-top-right.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }

        visionBottomCenterImage: file(
          relativePath: { eq: "vision-bottom-center.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }

        visionTopCenterImage: file(
          relativePath: { eq: "vision-top-center.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }

        visionBottomRightImage: file(
          relativePath: { eq: "vision-bottom-right.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `
  );

  const topRightImage = getImage(visionTopRightImage);
  const bottomCenterImage = getImage(visionBottomCenterImage);
  const topCenterImage = getImage(visionTopCenterImage);
  const bottomRightImage = getImage(visionBottomRightImage);

  const bgTopRightImage = convertToBgImage(topRightImage);
  const bgBottomCenterImage = convertToBgImage(bottomCenterImage);
  const bgTopCenterImage = convertToBgImage(topCenterImage);
  const bgBottomRightImage = convertToBgImage(bottomRightImage);

  return (
    <Layout 
      helmetTitle="Credito on line alle famiglie: scopri chi siamo - Prestitifaidate"
      helmetDescription="Siamo una Fintech Company brand di Gruppo Santamaria S.p.A.: credito on line e vendita a distanza di Finanziamenti con Cessione del Quinto e Mutui Casa."
    >
      <StyledWpPage id="page-content" color="transparent">
        <div className="wp-page__header mb-5">
          <div className="wrapper container d-flex align-items-center position-relative justify-content-between">
            <div className="d-flex align-items-center w-100 justify-content-between content pt-5 pt-md-0">
              <div className="d-flex flex-column">
                <span className="color-yellow fw-bold fs-3">Un team di professionisti</span>
                <span className="color-gray fs-4">per realizare i tuoi desideri</span>
              </div>
              <StaticImage
                src="../assets/images/testata-conosciamoci-meglio-persone.png"
                className="img-fluid my-5"
                alt="Persone"
                placeholder="blurred"
                layout="constrained"
                width={873}
                height={329}
              />
            </div>
          </div>
        </div>
        <div id="page-content" className="container mx-auto">
          <div className="wp-page__slug mb-5 mt-4 fs-5">
            <Link to="/" className="fw-bold">
              HOME
            </Link>{" "}
            &gt; <Link to="/chi-siamo/">Chi siamo</Link> &gt;{" "}
            <Link to="/conosciamoci-meglio/">Conosciamoci meglio</Link>
          </div>
          <h1 className="wp-page__title mb-5 display-6 fw-bold">
            Conosciamoci meglio
          </h1>
          <div className="wp-page__content">
            <p>Prestitifaidate.it è una <strong>Fintech Company</strong> brand di <strong>Gruppo Santamaria S.p.A.</strong> specializzata nel credito on line alle famiglie.</p>
            <p>Svolge attività per la <strong>vendita a distanza di Finanziamenti con Cessione del Quinto e Mutui per la Casa</strong>. Il servizio segue le logiche del <em>"Self Service"</em> nel mondo del credito.</p>
            <p>PrestitiFaidate.it si basa su una <strong>piattaforma di Conversational</strong> AI che permette, tramite assistenti virtuali evoluti e interfacce conversazionali, di individuare, attraverso semplici comandi vocali, la migliore soluzione di finanziamento. Questa formula permette un <strong>forte risparmio di tempo ma soprattutto di costi</strong>, in quanto, grazie al basso impatto di costi operativi, la società riesce a proporre <strong>tassi d'interesse molto competitivi</strong> rispetto al mercato di riferimento.</p>
            <p>Il tutto ovviamente garantito da un identity provider certificato per completare il processo d'istruttoria.</p>
            <StaticImage
              src="../assets/images/conosciamoci-meglio-1.png"
              className="img-fluid mx-auto my-3"
              alt="Chi siamo"
              placeholder="blurred"
              layout="fixed"
              width={257}
              height={305}
            />

            <h2 className="wp-page__title mb-5 display-6 fw-bold">
              La Nostra Vision
            </h2>
            <p>
              La visione di società che portiamo avanti è fondata essenzialmente
              sulla sicurezza e sull’inclusività, valori che incarnano
              perfettamente la creazione e distribuzione di benessere diffuso.
              Un principio fondamentale che tiene in considerazione sia i
              bisogni privati che quelli collettivi.
            </p>
            <p>Da questa visione, scaturisce un progetto sociale fondato su:</p>
            <div className="row wp-page__vision my-5">
              <StyledBackgroundImage
                Tag="div"
                {...bgTopRightImage}
                preserveStackingContext
                className="col-12 col-lg-3 d-flex flex-column align-self-center wp-page__vision--top-right p-3"
                style={{ backgroundPosition: "top right" }}
              >
                <span className="fw-bold text-center">sensibilizzazione</span>{" "}
                <br />
                dei mercati finanziari nei confronti dei valori di finanza
                etica;
              </StyledBackgroundImage>
              <StyledBackgroundImage
                Tag="div"
                {...bgBottomCenterImage}
                preserveStackingContext
                className="col-12 col-lg-3 d-flex flex-column align-self-center wp-page__vision--bottom-center p-5"
                style={{ backgroundPosition: "bottom center" }}
              >
                <span className="fw-bold text-center">
                  inclusività e pari opportunità di accesso al credito
                </span>{" "}
                <br />
                per tutte le persone, affinché queste siano protagoniste e
                artefici dei propri progetti di vita;
              </StyledBackgroundImage>
              <StyledBackgroundImage
                Tag="div"
                {...bgTopCenterImage}
                preserveStackingContext
                className="col-12 col-lg-3 d-flex flex-column align-self-center wp-page__vision--top-center p-5"
                style={{ backgroundPosition: "top center" }}
              >
                <span className="fw-bold text-center">sostegno</span> <br />
                delle comunità e delle istituzioni che promuovono la sicurezza
                di accesso al credito;
              </StyledBackgroundImage>
              <StyledBackgroundImage
                Tag="div"
                {...bgBottomRightImage}
                preserveStackingContext
                className="col-12 col-lg-3 d-flex flex-column align-self-center wp-page__vision--bottom-right p-5"
                style={{ backgroundPosition: "bottom right" }}
              >
                <span className="fw-bold">rispetto e valorizzazione</span>{" "}
                <br />
                di tutte le esigenze di credito con particolare attenzione a
                quelle di pensionati e dipendenti pubblici.
              </StyledBackgroundImage>
            </div>

            <p>
              A tal fine, i nostri interlocutori privilegiati sono imprese e
              organizzazioni orientate a una visione più profonda e inclusiva
              dell’ economia reale.
            </p>
            <p>
              Si tratta di attori che condividono il nostro approccio ad “andare
              oltre”, e costruire insieme uno scenario sociale futuro,
              coniugando la dimensione umana e quella finanziaria.
            </p>
            <p>
              Uno scenario sociale futuro proprio perché contempla le esigenze
              di accesso al credito di tutti, al fine di generare un effetto
              migliorativo della qualità di vita.
            </p>
          </div>
          <div className="wp-page__blue-banner my-5 py-3 px-3 py-md-4 px-md-5">
            <h2>L’Accesso al Credito come Diritto della Persona</h2>
            <p>
              Partiamo dalla premessa che la fiducia è l’elemento cardine per
              instaurare corrette relazioni tra le persone e che, senza fiducia,
              non può esistere il credito. Consideriamo l’accesso al credito uno
              dei diritti della persona, ma lo integriamo, al contempo, con i
              principi di responsabilità, prudenza e correttezza. L’accesso al
              credito è un diritto che la persona esercita per concepire e
              realizzare il proprio progetto di vita: un diritto che tutela e fa
              avverare altri diritti.
            </p>
          </div>
          <section className="wp-page__mission mb-5">
            <h2 className="wp-page__title mb-5 display-6 fw-bold">
              La nostra Mission
            </h2>
            <p>
              Puntiamo a <span className="fw-bold">“rendere diffuso”</span> il
              livello di benessere non solo economico e materiale, ma anche
              quello attinente a esperienze, sensibilità e istanze diverse.
              Questo significa che vogliamo connotarci, sempre di più, come
              punto di incontro e relazione tra le varie istanze personali e
              collettive, nazionali e locali.
            </p>
            <p>
              Tutto questo al fine di promuovere lo sviluppo delle ricchezze
              esistente in tutte le comunità territoriali. Contrastiamo
              l’esclusione sociale attraverso specifiche offerte di credito
              personalizzate.
            </p>
            <div className="text-center lh-1">
              <h3 className="mt-3">
                Per noi, il credito è un diritto individuale che può favorire il
                bene collettivo.
              </h3>
              <span>Esso ha una doppio significato:</span>
            </div>
            <div className="row my-5 justify-content-center">
              <div className="col-12 col-lg-4 text-center align-self-end">
                <StaticImage
                  src="../assets/images/mission-personale.png"
                  className="mx-auto"
                  alt="Mission - personale"
                  placeholder="blurred"
                  layout="fixed"
                  width={216}
                  height={113}
                />
                <span className="fw-bold">Personale,</span>
                <p>
                  in quanto il singolo ottiene la risposta a bisogni e progetti
                  futuri
                </p>
              </div>
              <div className="col-12 col-lg-4 text-center align-self-end">
                <StaticImage
                  src="../assets/images/mission-sociale.png"
                  className="mx-auto"
                  alt="Mission - sociale"
                  placeholder="blurred"
                  layout="fixed"
                  width={159}
                  height={149}
                />
                <span className="fw-bold">Sociale,</span>
                <p>
                  in quanto il singolo ottiene la risposta a bisogni e progetti
                  futuri
                </p>
              </div>
            </div>
            <p>
              Pertanto, abbiamo l’obiettivo di sviluppare nuove opportunità di
              credito finalizzate alla tutela del potere d’acquisto e a supporto
              di quelle realtà che lavorano per il benessere collettivo,
              incoraggiando una cittadinanza attiva, consapevole e responsabile.
            </p>
          </section>
          <div className="wp-page__valori mb-5">
            <h2 className="wp-page__title mb-5 display-6 fw-bold">
              I nostri Valori
            </h2>
            <p>
              Siamo nati come{" "}
              <span className="fw-bold">azienda alternativa</span>, per essere{" "}
              <span className="fst-italic">“altro”</span> rispetto alla prassi
              corrente del{" "}
              <span className="fst-italic">“fare credito online”</span> e per
              cercare di migliorare le regole del mercato, attraverso:
            </p>
            <div className="row justify-content-center">
              <div className="wp-page__valori__item text-center col-12 col-lg-3 m-5">
                <h3 className="fw-bold fs-6">ETICA</h3>
                <p>
                  Promuoviamo la finanza etica, cioè un sistema che contribuisce
                  a creare interdipendenza tra solidarietà sociale e istanze
                  economiche
                </p>
              </div>
              <div className="wp-page__valori__item text-center col-12 col-lg-3 m-5">
                <h3 className="fw-bold fs-6">SICUREZZA</h3>
                <p>
                  Siamo il punto di riferimento per tutte le realtà online che
                  abbracciano la logica dell’accesso sicuro, consapevole e
                  responsabile al credito
                </p>
              </div>
              <div className="wp-page__valori__item text-center col-12 col-lg-3 m-5">
                <h3 className="fw-bold fs-6">TRASPARENZA</h3>
                <p>
                  Quello che abbiamo più a cuore è trasmettere ai nostri clienti
                  il nostro approccio verso un dialogo chiaro, semplice e
                  sincero
                </p>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="wp-page__valori__item text-center col-12 col-lg-3 m-5">
                <h3 className="fw-bold fs-6">INCLUSIVITÀ</h3>
                <p>
                  Portiamo avanti un modello di credito che contribuisce alla
                  creazione del benessere diffuso e alla tutela del potere
                  D’ACQUISTO
                </p>
              </div>
              <div className="wp-page__valori__item text-center col-12 col-lg-3 m-5">
                <h3 className="fw-bold fs-6">EMPATIA</h3>
                <p>
                  Ascoltiamo prima di agire. Il nostro atteggiamento si basa
                  sull’empatia risolutiva, quella che dimostra proattività,
                  prontezza e facilità nel rispondere a tutte le istanze di
                  credito
                </p>
              </div>
            </div>
            <p>
              Attraverso il nostro contributo, il ruolo dei cittadini si è
              potenziato ed è diventato sempre più consapevole rispetto
              all’importanza dell’accesso sicuro al credito online.
            </p>
          </div>
          <DefaultButton
            title="leggi il nostro manifesto per la sostenibilità sociale"
            className="btn-outline-dark mx-auto d-block mb-5"
            url={"/manifesto/"}
          />
        </div>
      </StyledWpPage>
      <TargetFullBanner
        title="Dipendente pubblico, privato o pensionato?"
        description="Richiedere un prestito non è mai stato così facile, veloce e conveniente!"
        className="mt-2"
      />
      <DipendentePensionatoPrivato />
      <SliderOne />
      <MetodoPiuVeloce className="mt-2" />
      <CessioneFinanziamenti className="mb-5" />
    </Layout>
  );
}
